html {
  overflow-y: scroll;
  font-family: arial, sans-serif;
}

.app-container {
  margin: 1% 10%;
}

.login-form {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.form-element {
  padding: 10px 0;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.menu-row {
  display: flex;
  justify-content: left;
  margin-bottom: 50px;
}

.menu-item {
  margin-right: 20px;
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  cursor: pointer;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.regular-text-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}
.regular-text-button:not(:last-child) {
  margin-right: 30px;
}

.regular-text-redirect {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  cursor: pointer;
  font-size: 16px;
}
.regular-text-redirect:not(:last-child) {
  margin-right: 30px;
}

.button-group {
  display: flex;
  justify-content: flex-start;
}

.button-group-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;
}

.content-table {
  width: 100%;
  border-collapse: collapse;
}

.content-table tr {
  border-bottom: 1pt solid lightslategray;
  padding-left: 10px;
}

.content-table thead th {
  border: 1pt solid lightslategray;
}

.content-table thead td {
  border: 1pt solid lightslategray;
  padding-left: 10px;
}

.content-table-header {
  padding: 5px;
  text-align: left;
}

.search-input {
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.search-button {
  width: 100%;
}

.content-container {
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}
.content-container > *:not(:last-child) {
  margin-bottom: 20px;
}

.search-status-msg {
  justify-self: flex-end;
}

.full-width-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.full-width-row > *:not(:last-child) {
  margin-right: 20px;
}

.popup-window {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.popup-window > *:not(:last-child) {
  margin-bottom: 20px;
}

.buttons-inline {
  width: 100%;
  max-width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.buttons-inline > *:not(:last-child) {
  /* display: inline; */
  margin-right: 10px;
  margin-bottom: 10px;
}
.text-button-wrapper {
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  display: flex;
  justify-content: space-between;
  height: max-content;
}
.square-button-text {
  text-align: center;
  padding: 5px;
  height: 100%;
  white-space: nowrap;
}

.self-start {
  align-self: flex-start;
}

.edited-cell {
  background-color: honeydew;
  font-weight: bold;
}

.modal-vertical-scroll {
  overflow-y: auto;
  max-height: 60vh;
  width: 100%;
}
